import React from 'react'
import { useTranslation } from 'react-i18next';
const Error = () => {
  const [t,i18n]=useTranslation();


  return (
    <div className='errorclass' style={{color:"white"}}>
      <h1 >{t('Error : 404')}</h1>
      <p>{t('Please Try Again')}</p>
    </div>
  )
}

export default Error
