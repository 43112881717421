
import React from 'react'
import { Zoom } from 'react-awesome-reveal'
import  aboutimage  from "../images/besanlogo01.png";
import { useTranslation } from 'react-i18next';
const About = () => {
   const [t,i18n]=useTranslation();  
  return (
    <div>
    <div className="container-xxl py-5">
    <div className="container">
      
        <div className="row g-5 my-5">
            
            <div className="col-lg-6   text-white" style={{backgroundColor:"rgba(0, 0, 0, .5)",padding:"10px" ,borderRadius:"10px"}} >
                <div className="h-100 ">
                    <Zoom> <h1 className="text-primary text-uppercase mb-2"> {t('About Us')}</h1></Zoom>
                    <Zoom> <h1 className="display-6 mb-4">{t('Besan vision')}</h1></Zoom>
                    <Zoom>  
                     <p> {t("aboutdesc")}</p>
                   
                     </Zoom>
                     <Zoom>
                     <div className="row g-2 mb-4">
                        <div className="col-sm-6">
                            <i className="fa fa-check text-primary me-2"></i>{t('Quality Products')}
                        </div>
                        <div className="col-sm-6">
                            <i className="fa fa-check text-primary me-2"></i>{t('Custom Products')}
                        </div>
                        <div className="col-sm-6">
                            <i className="fa fa-check text-primary me-2"></i>{t('Online Order')}
                        </div>
                        <div className="col-sm-6">
                            <i className="fa fa-check text-primary me-2"></i>{t('Home Delivery')}
                        </div>
                    </div>
                    </Zoom>
                    <button className="ptn " href="">{t('Read More')}</button>
                </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center align-items-center" >
                 <img className=''  src={aboutimage} alt=''></img>
            </div>
        </div>
    </div>
</div>
    </div>
  )
}

export default About
