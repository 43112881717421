

const token='3|zKVscojWs7pH6yl3PeWrUHkZrCQ9K3rc8EY8sgRM';
export const config={
  headers: {
  'content-type': 'multipart/form-data',
  Authorization: `Bearer ${token}`
}};

export const host='https://design-besan.com/laravel';
 