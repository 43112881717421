import React from 'react'
import { Alert, Col, Row, Table } from 'react-bootstrap'
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { host ,config} from '../actions';

const Categories = () => {

  const [t,i18n]=useTranslation();
  const [categories, setcategories] = useState([]);
  const [cardformaddstate, setcardformaddstate] = useState('none');
  const [cardformeditstate, setcardformeditstate] = useState('none');
  const [cardaddbtnstate, setcardaddbtnstate] = useState('visible');
  const [fmessages, setfmessages] = useState('');
  const [category, setcategory] = useState({
    name_ar: "",
    name_en: "",
    desc_ar: "",
    desc_en: "",
  });
  const [currentcategory, setcurrentcategory] = useState({
    id: "",
    name_ar: "",
    name_en: "",
    desc_ar: "",
    desc_en: "",
  });
  
  function closeaddform(){
    setcardformaddstate('none')
    setcardaddbtnstate('visible')
  }
  function closeeditform(){
   setcardformeditstate('none')
  }
  function handledaddform() {
    setcardformaddstate('block')
    setcardformeditstate('none')
    setcardaddbtnstate('hidden')
  }
  function handlededitform(item) {
    setcurrentcategory(item)
    setcardformaddstate('none')
    setcardformeditstate('block')
    setcardaddbtnstate('visible')
  }

  function hundleCat(e) {
    const newcat = { ...category };
    newcat[e.target.id] = e.target.value;
    setcategory(newcat);
    console.log(newcat);
  }
  function hundlecurrentCat(e) {
    const newcat = { ...currentcategory };
    newcat[e.target.id] = e.target.value;
    setcurrentcategory(newcat);
    console.log(newcat);
  }



  useEffect(() => {
    axios.get(host+'/api/categories').then(res => {
      setcategories(res.data);
      console.log(res.data);
    });
  }, []);

  function getAllCats() {
    axios.get(host+'/api/categories').then(res => {
      setcategories(res.data);
    });
  }

  const PostForm = (e) => {
    e.preventDefault();
    axios.post(host+'/api/categories', {
      name_ar: category.name_ar,
      name_en: category.name_en,
      desc_ar: category.desc_ar,
      desc_en: category.desc_en,
    },config).then(res => {
      setcardaddbtnstate('visible');
      setcardformaddstate('none')
      getAllCats();
      setfmessages('')

    }, err => {
      setfmessages(err.response.data.message)
    }
    );
  }
  const EditForm = (e) => {
    e.preventDefault();
    axios.put(host+'/api/categories/' + currentcategory.id, {
      name_ar: currentcategory.name_ar,
      name_en: currentcategory.name_en,
      desc_ar: currentcategory.desc_ar,
      desc_en: currentcategory.desc_en,
    },config).then(res => {
      setcardformeditstate('none')
      getAllCats();
      setfmessages('')

    }, err => {
      setfmessages(err.response.data.message)
    }
    );
  }
  const delCat = (id) => {
    axios.delete(host+'/api/categories/' + id,config).then(res => {
      getAllCats();
      setfmessages('')

    }, err => {
      setfmessages(err.response.data.message)
    }

    )
  }
  return (
    <div className="tab-pane my-5" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="1">
      {/* form to add */}
      <Row className='mb-3'>
        <div className="card cardform" style={{ display: cardformaddstate }}>
          <div className="card-header text-white bg-dark d-flex justify-content-between align-items-center">
            <div className='col-3 text-start'>Add Form</div>
            <div className='col-3 text-end'> <button className='btn ms-auto me-1 bg-white text-black ' onClick={closeaddform}>x</button></div>
          </div>
          <div className="card-body">
            <form action="" onSubmit={(e) => PostForm(e)}>
              <div className="taps">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">English</button>
                  </li>
                  <li className="nav-item">
                    <button className="nav-link " id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Arabic</button>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tap_en tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabIndex="0">

                    <div className="col-md-6 mt-2"  >
                      <label htmlFor="name_en" className="form-label" style={{ color: "black" }}>Name_En</label>
                      <input type="text" onChange={(e) => hundleCat(e)} className="form-control" name="name_en" value={category.name_en}
                        id="name_en" />
                    </div>

                    <div className="col-md-6 mt-2">
                      <label htmlFor="desc_en" className="form-label" style={{ color: "black" }}>Description_En:</label>
                      <textarea type="text" onChange={(e) => hundleCat(e)} name='desc_en' value={category.desc_en} style={{ height: "150px" }} className="form-control" id="desc_en">

                      </textarea>
                    </div>

                  </div>
                  <div className="tap_ar tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabIndex="0">
                    <div className="col-md-6 mt-2">
                      <label htmlFor="name_ar" className="form-label" style={{ color: "black" }}>Name_Ar :</label>
                      <input type="text" onChange={(e) => hundleCat(e)} className="form-control" name="name_ar" value={category.name_ar}
                        id="name_ar" />
                    </div>

                    <div className="col-md-6 mt-2">
                      <label htmlFor="desc_ar" className="form-label" style={{ color: "black" }}>Description_Ar :</label>
                      <textarea type="text" onChange={(e) => hundleCat(e)} name='desc_ar' value={category.desc_ar} style={{ height: "150px" }} className="form-control" id="desc_ar">

                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 mt-2">
                <button type="submit" className="btn bg-black text-white">Add</button>
              </div>

            </form>
          </div>
        </div>
      </Row>
      {/* form to edit */}
      <Row className='mb-3'>
        <div className="card cardform" style={{ display: cardformeditstate }}>
          <div className="card-header text-white bg-dark d-flex justify-content-between align-items-center">
            <div className='col-3 text-start'>Edit Form</div>
            <div className='col-3 text-end'> <button className='btn ms-auto me-1 bg-white text-black' onClick={closeeditform}>x</button></div>

          </div>
          <div className="card-body">
            <form action="" onSubmit={(e) => EditForm(e)}>
              <div className="taps">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home2" type="button" role="tab" aria-controls="nav-home" aria-selected="true">English</button>
                  </li>
                  <li className="nav-item">
                    <button className="nav-link " id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile2" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Arabic</button>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tap_en tab-pane fade show active" id="nav-home2" role="tabpanel" aria-labelledby="nav-home2-tab" tabIndex="0">

                    <div className="col-md-6 mt-2"  >
                      <label htmlFor="name_en" className="form-label" style={{ color: "black" }}>Name_En</label>
                      <input type="text" onChange={(e) => hundlecurrentCat(e)} className="form-control" name="name_en" value={currentcategory.name_en}
                        id="name_en" />
                    </div>

                    <div className="col-md-6 mt-2">
                      <label htmlFor="desc_en" className="form-label" style={{ color: "black" }}>Description_En:</label>
                      <textarea type="text" onChange={(e) => hundlecurrentCat(e)} name='desc_en' value={currentcategory.desc_en ? currentcategory.desc_en : ''} style={{ height: "150px" }} className="form-control" id="desc_en">

                      </textarea>
                    </div>

                  </div>
                  <div className="tap_ar tab-pane fade" id="nav-profile2" role="tabpanel" aria-labelledby="nav-profile2-tab" tabIndex="0">
                    <div className="col-md-6 mt-2">
                      <label htmlFor="name_ar" className="form-label" style={{ color: "black" }}>Name_Ar :</label>
                      <input type="text" onChange={(e) => hundlecurrentCat(e)} className="form-control" name="name_ar" value={currentcategory.name_ar}
                        id="name_ar" />
                    </div>

                    <div className="col-md-6 mt-2">
                      <label htmlFor="desc_ar" className="form-label" style={{ color: "black" }}>Description_Ar :</label>
                      <textarea type="text" onChange={(e) => hundlecurrentCat(e)} name='desc_ar' value={currentcategory.desc_ar ? currentcategory.desc_ar : ''} style={{ height: "150px" }} className="form-control" id="desc_ar">

                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 mt-2">
                <button type="submit" className="btn bg-black text-white">Update</button>
              </div>

            </form>
          </div>
        </div>
      </Row>
      {/* flash messages */}
      <Row>
        {
          fmessages ? (<Alert variant={'danger'}>{fmessages}</Alert>) : ''
        }
      </Row>
      {/* new category */}
      <Row className='justify-content-end mb-3'>
        <Col sm="3" className={`${i18n.language=="en" ? "text-end":"text-start"}`}><button className='btn bg-dark text-white' style={{ visibility: cardaddbtnstate }} onClick={handledaddform}>Add New Category</button></Col>

      </Row>
      {/* table to display categories */}
      <Row style={{overflowX:"scroll"}}>
        <Table striped bordered hover variant="dark" >
          <thead>
            <tr>
              <th>Category Id</th>
              <th>Name_En</th>
              <th>Name_Ar</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              categories.length >= 1 ? (categories.map(item => {
                return (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name_en}</td>
                    <td>{item.name_ar}</td>
                    <td>
                      <button className='btn' onClick={() => delCat(item.id)}><i className="bi bi-file-x text-danger mx-2"></i></button>
                      <button className='btn text-white' onClick={() => handlededitform(item)} ><i className="bi bi-pen-fill"></i></button>
                    </td>
                  </tr>
                );

              })) : (
                <tr >
                  <td colSpan={4} className="text-center">'no data ...'</td>
                </tr>)
            }

          </tbody>
        </Table>
      </Row>

    </div>
  )
}

export default Categories
