import React,{useEffect} from 'react'
import '../css/style.css';
import { Col, Figure, Row } from "react-bootstrap";
import LogoFiger from './LogoFiger';
import { Bounce, Zoom } from 'react-awesome-reveal';
import {Link} from 'react-router-dom';
import  besanicon  from "../images/besanicon03.png";
import { useTranslation } from 'react-i18next';


const Homestatic = () => {
  const [t,i18n]=useTranslation();
  useEffect(() => {
    const timer = setTimeout(() => {
      document.querySelector('.boxcon').classList.toggle('hide')
    }, 4900);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='homestyle p-2 position-relative'>
      
      <Row className='mb-1' >
        <Col sm="12" className='d-flex justify-content-center logoonhomestatic'>
           <LogoFiger />
        </Col>
        <Col sm="12" className='landingheader'>
         <h1 className='text-center' style={{color:"white",letterSpacing:"5px",zIndex:"99"}} >
           <Bounce left cascade delay={2000} duration={200} >
             {t('Besan')}
           </Bounce>
         </h1>
         <p className='text-center landingdescstatic' style={{color:"white",zIndex:"99"}} >
             {t('All the assets you need, in one place')}
        </p>
       </Col>
      </Row>
        
     <Row  className='tapscontainers-static  justify-content-center m-0 p-0' >
        
             <Col sm="6" md="2" className='d-flex p-0 justify-content-center'>
                  <Link to="/services">
                  <Figure className='figiconcontainer'>
                       <Figure.Image
                         width={75}
                         height={64.5}
                         alt="besan design"
                         src={besanicon}
                       />
                       <Figure.Caption className='iconcaption'>
                       {t('Paper Publications')} 
                       </Figure.Caption>
                    </Figure>
                  </Link>
               </Col>
               <Col sm="6" md="2" className='d-flex p-0 justify-content-center'>
                  <Link to="/services">
                  <Figure className='figiconcontainer'>
                       <Figure.Image
                         width={75}
                         height={64.5}
                         alt="besan design"
                         src={besanicon}
                       />
                       <Figure.Caption className='iconcaption'>
                       {t('External Panels')} 
                       </Figure.Caption>
                    </Figure>
                  </Link>
               </Col>
               <Col sm="12" md="2" className='d-flex p-0 justify-content-center'>
                  <Link to="/services">
                  <Figure className='figiconcontainer'>
                       <Figure.Image
                        width={75}
                         height={64.5}
                         alt="besan design"
                         src={besanicon}
                       />
                       <Figure.Caption className='iconcaption'>
                       {t('Decoration')} 
                       </Figure.Caption>
                    </Figure>
                  </Link>
               </Col>
               <Col sm="6" md="2" className='d-flex p-0 justify-content-center'>
                  <Link to="/services">
                  <Figure className='figiconcontainer'>
                       <Figure.Image
                        width={75}
                         height={64.5}
                         alt="besan design"
                         src={besanicon}
                       />
                       <Figure.Caption className='iconcaption'>
                       {t('Shields And Gifts')} 
                       </Figure.Caption>
                    </Figure>
                  </Link>
               </Col>
               <Col sm="6" md="2" className='d-flex p-0 justify-content-center'>
                  <Link to="/services">
                  <Figure className='figiconcontainer'>
                       <Figure.Image
                        width={75}
                         height={64.5}
                         alt="besan design"
                         src={besanicon}
                       />
                       <Figure.Caption className='iconcaption'>
                       {t('E-Marketing')}
                       </Figure.Caption>
                    </Figure>
                  </Link>
               </Col>
               <Col sm="6" md="2" className='d-flex p-0 justify-content-center'>
                  <Link to="/services">
                  <Figure className='figiconcontainer'>
                       <Figure.Image
                        width={75}
                         height={64.5}
                         alt="besan design"
                         src={besanicon}
                       />
                       <Figure.Caption className='iconcaption'>
                       {t('Online Services')}
                       </Figure.Caption>
                    </Figure>
                  </Link>
               </Col>
               <Col sm="6" md="2" className='d-flex p-0 justify-content-center'>
                  <Link to="/services">
                  <Figure className='figiconcontainer'>
                       <Figure.Image
                         width={75}
                         height={64.5}
                         alt="besan design"
                         src={besanicon}
                       />
                       <Figure.Caption className='iconcaption'>
                       {t('Our Work')} 
                       </Figure.Caption>
                    </Figure>
                  </Link>
               </Col>
               <Col sm="6" md="2" className='d-flex p-0 justify-content-center'>
                  <Link to="/about">
                  <Figure className='figiconcontainer'>
                       <Figure.Image
                        width={75}
                         height={64.5}
                         alt="besan design"
                         src={besanicon}
                       />
                       <Figure.Caption className='iconcaption'>
                         {t('about')}
                       </Figure.Caption>
                    </Figure>
                  </Link>
               </Col>
               <Col sm="6" md="2" className='d-flex p-0 justify-content-center'>
                  <Link to="/contact">
                  <Figure className='figiconcontainer'>
                       <Figure.Image
                        width={75}
                         height={64.5}
                         alt="besan design"
                         src={besanicon}
                       />
                       <Figure.Caption className='iconcaption'>
                         {t('Contact')}
                       </Figure.Caption>
                    </Figure>
                  </Link>
               </Col>
            
     </Row>
      
      <div className='borderround-static'></div>
     
    <div style={{width:"80%"}} className='row mapclass my-5 rounded-3 shadow-lg'>
       <iframe  className='rounded-3' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3713.2450746940267!2d39.93379311493995!3d21.458898485766905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x979e241848f3dd2c!2zMjHCsDI3JzMyLjAiTiAzOcKwNTYnMDkuNSJF!5e0!3m2!1sen!2seg!4v1666663578743!5m2!1sen!2seg" 
        width="600" 
         height="300"
         style={{border:"0",width:"100%",borderRadius:"10px"}} 
         loading="lazy" 
         >
       </iframe>
    </div>
      
    </div>
  )
}

export default Homestatic
