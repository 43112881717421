
import { Zoom } from 'react-awesome-reveal'
import { Image } from 'react-bootstrap'
import '../css/style.css';
import emailjs from 'emailjs-com';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
const Contact = () => {
  const [t, i18n] = useTranslation();
  const form = useRef();
  const sendmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_yl7ztkg',
      'template_72e13ad',
      form.current,
      'iu5qeszjp1nscBtfy')
      .then(function (response) {
        console.log('SUCCESS!', response.status, response.text);
        resetfun();
        document.getElementById('flushalert').classList.remove('flushclass');
        document.getElementById('flushalert').classList.add('flushclass');


      }, function (err) {
        console.log('FAILED...', err);
      });
  }
  const resetfun = () => {
    form.current.reset();
  }
  return (
    <div>
      <div className="container py-5">
        <Zoom>
          <div className="row g-4 mb-5 ">

            <div className="col-lg-4 ">
              <div className="h-100 bg-light d-flex  p-5 shadow-lg rounded-3">
                <div className="btn-lg-square bg-white flex-shrink-0">
                  <i className="fa fa-map-marker-alt text-primary"></i>
                </div>
                <div className={`${i18n.language == "en" ? 'ms-4' : 'me-4'}`}>
                  <p className="mb-2">
                    <span className={`text-primary ${i18n.language == 'en' ? 'me-2' : 'ms-2'}`}></span>{t('Address')}
                  </p>
                  <h5 className="mb-0">{t('Makkah Al-Mukarramah Al-Shari’a Scheme 10 Public Security Street next to the 10th Al-Badr Medical Complex')}</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="h-100 bg-light d-flex  p-5 shadow-lg rounded-3">
                <div className="btn-lg-square bg-white flex-shrink-0">
                  <i className="fa fa-phone-alt text-primary"></i>
                </div>
                <div className={`${i18n.language == 'en' ? 'ms-4' : 'me-4'}`}>
                  <p className="mb-2">
                    <span className={`text-primary ${i18n.language == 'en' ? 'me-2' : 'ms-2'}`}></span>{t('Call Now')}
                  </p>
                  <h5 className="mb-0">0553333937</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="h-100 bg-light d-flex  p-5 shadow-lg rounded-3">
                <div className="btn-lg-square bg-white flex-shrink-0">
                  <i className="fa fa-envelope-open text-primary"></i>
                </div>
                <div className={`${i18n.language == 'en' ? 'ms-4' : 'me-4'}`}>
                  <p className="mb-2">
                    <span className={`text-primary ${i18n.language == 'en' ? 'me-2' : 'ms-2'}`}></span>{t('Mail Now')}
                  </p>
                  <h5 className="mb-0">besan.designs@gmail.com</h5>
                </div>
              </div>
            </div>
          </div>


          <Zoom>
            <div className='row mapclass my-5 rounded-3 shadow-lg'>

              <iframe className='rounded-3' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3713.2481007534175!2d39.9360331!3d21.458779699999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c20127206e8563%3A0x542dca2827ab663d!2z2KrYtdin2YXZitmFINio2YrYs9in2YY!5e0!3m2!1sar!2ssa!4v1670618574242!5m2!1sar!2ssa"
                width="600"
                height="450"
                style={{ border: "0" }}
                loading="lazy"
              >
              </iframe>
            </div>
          </Zoom>


        </Zoom>
        <div className="container text-white">
          <Zoom>
            <div className="text-center mx-auto mb-5 " style={{ maxWidth: "500px", backgroundColor: "rgba(0, 0, 0, .5)", padding: "10px", borderRadius: "10px" }}>
              <p className="text-primary text-uppercase mb-2">{t('Contact Us')}</p>
              <h1 className="display-6 mb-4">{t('If You Have Any Query, Please Contact Us')}</h1>
            </div>
          </Zoom>

          <Zoom>
            <div className="row g-0 justify-content-center">

              <div className="col-lg-8 ">

                <form onSubmit={sendmail} ref={form} id="contactformmo">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="name" name='name' placeholder={t("Your Name")} required></input>

                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input type="email" className="form-control" id="email" name='user_email' placeholder={t("Your Email")} required></input>

                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="phone" pattern="[05][0-9]{9}" title={t('no must be like 0512345678')} name='phone' placeholder={t("Phone")} required></input>

                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea className="form-control" name='message' placeholder={t("Leave a message here")} id="message" style={{ height: " 200px" }}></textarea>

                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <button className="ptn" style={{ width: "130px" }} type="submit" >{t('Send Message')}</button>
                    </div>
                  </div>
                </form>
              </div>


            </div>
          </Zoom>
          <div className='row mt-2 justify-content-center'>
            <div id='flushalert' className=" col-6 alert alert-success " style={{ opacity: "0" }} role="alert" >
              {t('success Operation')}
            </div>
          </div>
        </div>



      </div>
    </div>
  )
}

export default Contact
