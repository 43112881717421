import React from 'react'
import { Alert, Col, Figure, Row, Table } from 'react-bootstrap'
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { host ,config} from '../actions';
const Products = () => {
 
  const [t, i18n] = useTranslation();
  const [products, setproducts] = useState([]);
  const ref = useRef();
  const [categories, setcategories] = useState([]);
  const [cardformaddstate, setcardformaddstate] = useState('none');
  const [cardformeditstate, setcardformeditstate] = useState('none');
  const [cardaddbtnstate, setcardaddbtnstate] = useState('visible');
  const [fmessages, setfmessages] = useState('');
  const [currentproduct, setcurrentproduct] = useState({});

  function closeaddform() {
    setcardformaddstate('none')
    setcardaddbtnstate('visible')
  }
  function closeeditform() {
    setcardformeditstate('none')
  }
  function handleaddform() {
    setcardformaddstate('block')
    setcardformeditstate('none')
    setcardaddbtnstate('hidden')
  }
  function handleeditform(item) {
    setcurrentproduct(item)
    setcardformaddstate('none')
    setcardformeditstate('block')
    setcardaddbtnstate('visible')
  }


  function hundlecurrentCat(e) {
    const newcat = { ...currentproduct };
    newcat[e.target.id] = e.target.value;
    setcurrentproduct(newcat);
  }



  useEffect(() => {
    axios.get(host+'/api/products').then(res => {
      setproducts(res.data);
      
    });
    axios.get(host+'/api/categories').then(res => {
      setcategories(res.data);
    });
  }, []);

  function getAllProducts() {
    axios.get(host+'/api/products').then(res => {
      setproducts(res.data);
    });
  }

  const PostForm = (e) => {
    const formData = new FormData(document.getElementById('formtoaddproduct'));
    e.preventDefault();
    axios.post(host+'/api/products',formData,config).then(res => {
      setcardaddbtnstate('visible');
      setcardformaddstate('none')
      getAllProducts();
      setfmessages('')

    }, err => {
      setfmessages(err.response.data.message)
    }
    );
  }
  const EditForm = (e) => {
    e.preventDefault();
    const formData2 = new FormData(document.getElementById('formtoeditproduct'));
    axios.put(host+'/api/products/' + currentproduct.id,formData2,config
    ).then(res => {
      setcardformeditstate('none')
      getAllProducts();
      setfmessages(res.data.message)

    }, err => {
      setfmessages(err.response.data.message)
    }
    );
  }
  const deletProduct = (id) => {
    axios.delete(host+'/api/products/' + id,config).then(res => {
      getAllProducts();
      setfmessages('')

    }, err => {
      setfmessages(err.response.data.message)
    }

    )
  }

  return (
    <div className="tab-pane fade show active my-5" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
      {/* form to add */}
      <Row className='mb-3'>
        <div className="card cardform" style={{ display: cardformaddstate }}>
          <div className="card-header text-white bg-dark d-flex justify-content-between align-items-center">
            <div className='col-3 text-start'>Add Form</div>
            <div className='col-3 text-end'> <button className='btn ms-auto me-1 bg-white text-black ' onClick={closeaddform}>x</button></div>
          </div>
          <div className="card-body">
            <form id='formtoaddproduct' action="" onSubmit={(e) => PostForm(e)} encType='multipart/form-data'>
              <div className="taps">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <button className="nav-link active" id="nav-home4-tab" data-bs-toggle="tab" data-bs-target="#nav-home4" type="button" role="tab" aria-controls="nav-home4" aria-selected="true">English</button>
                  </li>
                  <li className="nav-item">
                    <button className="nav-link " id="nav-profile4-tab" data-bs-toggle="tab" data-bs-target="#nav-profile4" type="button" role="tab" aria-controls="nav-profile4" aria-selected="false">Arabic</button>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tap_en tab-pane fade show active" id="nav-home4" role="tabpanel" aria-labelledby="nav-home4-tab" tabIndex="0">

                    <div className="col-md-6 mt-2"  >
                      <label htmlFor="name_en" className="form-label" style={{ color: "black" }}>Name_En :</label>
                      <input type="text" className="form-control" name="name_en"
                        id="name_en" />
                    </div>

                    <div className="col-md-6 mt-2">
                      <label htmlFor="desc_en" className="form-label" style={{ color: "black" }}>Description_En :</label>
                      <textarea type="text" name='desc_en' style={{ height: "150px" }} className="form-control" id="desc_en">

                      </textarea>
                    </div>

                  </div>
                  <div className="tap_ar tab-pane fade" id="nav-profile4" role="tabpanel" aria-labelledby="nav-profile4-tab" tabIndex="0">
                    <div className="col-md-6 mt-2">
                      <label htmlFor="name_ar" className="form-label" style={{ color: "black" }}>Name_Ar :</label>
                      <input type="text" className="form-control" name="name_ar"
                        id="name_ar" />
                    </div>

                    <div className="col-md-6 mt-2">
                      <label htmlFor="desc_ar" className="form-label" style={{ color: "black" }}>Description_Ar :</label>
                      <textarea type="text" name='desc_ar' style={{ height: "150px" }} className="form-control" id="desc_ar">

                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <label htmlFor='cat_id' className="form-label" style={{ color: "black" }}>Category :</label>
                <select id="cat_id" name='cat_id' className='form-control select-control'>
                  {categories.length >= 1 ? (categories.map((item) => {
                    return (<option key={item.id} value={item.id}>{item.name_en}</option>)
                  })) : <option value="">No data</option>
                  }
                </select>
              </div>
              <div className="col-md-6 mt-2">
                <label htmlFor='image' className="form-label" style={{ color: "black" }}>Image :</label>
                <input type="file" id="image" name='image' className="form-control" />
              </div>
              <div className="col-6 mt-2">
                <button type="submit" className="btn bg-black text-white">Add</button>
              </div>

            </form>
          </div>
        </div>
      </Row>
      {/* form to edit */}
      <Row className='mb-3'>
        <div className="card cardform" style={{ display: cardformeditstate }}>
          <div className="card-header text-white bg-dark d-flex justify-content-between align-items-center">
            <div className='col-3 text-start'>Edit Form</div>
            <div className='col-3 text-end'> <button className='btn ms-auto me-1 bg-white text-black' onClick={closeeditform}>x</button></div>

          </div>
          <div className="card-body">
            <form id="formtoeditproduct" action="" onSubmit={(e) => EditForm(e)} encType='multipart/form-data'>
              <div className="taps">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <button className="nav-link active" id="nav-home3-tab" data-bs-toggle="tab" data-bs-target="#nav-home3" type="button" role="tab" aria-controls="nav-home3" aria-selected="true">English</button>
                  </li>
                  <li className="nav-item">
                    <button className="nav-link " id="nav-profile3-tab" data-bs-toggle="tab" data-bs-target="#nav-profile3" type="button" role="tab" aria-controls="nav-profile3" aria-selected="false">Arabic</button>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tap_en tab-pane fade show active" id="nav-home3" role="tabpanel" aria-labelledby="nav-home3-tab" tabIndex="0">
                    <div className="col-md-6 mt-2"  >
                      <label htmlFor="name_en" className="form-label" style={{ color: "black" }}>Name_En</label>
                      <input type="text" onChange={(e) => hundlecurrentCat(e)} className="form-control" name="name_en" value={currentproduct.name_en}
                        id="name_en" />
                    </div>
                    <div className="col-md-6 mt-2">
                      <label htmlFor="desc_en" className="form-label" style={{ color: "black" }}>Description_En:</label>
                      <textarea type="text" onChange={(e) => hundlecurrentCat(e)} name='desc_en' value={currentproduct.desc_en ? currentproduct.desc_en : ''} style={{ height: "150px" }} className="form-control" id="desc_en">
                      </textarea>
                    </div>
                  </div>
                  <div className="tap_ar tab-pane fade" id="nav-profile3" role="tabpanel" aria-labelledby="nav-profile3-tab" tabIndex="0">
                    <div className="col-md-6 mt-2">
                      <label htmlFor="name_ar" className="form-label" style={{ color: "black" }}>Name_Ar :</label>
                      <input type="text" onChange={(e) => hundlecurrentCat(e)} className="form-control" name="name_ar" value={currentproduct.name_ar}
                        id="name_ar" />
                    </div>

                    <div className="col-md-6 mt-2">
                      <label htmlFor="desc_ar" className="form-label" style={{ color: "black" }}>Description_Ar :</label>
                      <textarea type="text" onChange={(e) => hundlecurrentCat(e)} name='desc_ar' value={currentproduct.desc_ar ? currentproduct.desc_ar : ''} style={{ height: "150px" }} className="form-control" id="desc_ar">

                      </textarea>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-6 mt-2">
                <label htmlFor='cat_id' className="form-label" style={{ color: "black" }}>Category :</label>
                <select ref={ref} id="cat_id" name='cat_id' value={currentproduct.cat_id} onChange={(e) => hundlecurrentCat(e)} className='form-control select-control'>
                  {categories.length >= 1 ? (categories.map((item) => {
                    return (<option key={item.id} value={item.id} >{item.name_en}</option>)
                  })) : <option value="">No data</option>
                  }
                </select>
              </div>
              <div className="col-6 mt-2">
                <label htmlFor='image' className="form-label" style={{ color: "black" }}>Image :</label>
                <input type="file" id="image" name='image' className="form-control" />
              </div>
              <div className="col-6 mt-2">
                <button type="submit" className="btn bg-black text-white">Update</button>
              </div>

            </form>
          </div>
        </div>
      </Row>
      {/* flash messages */}
      <Row>
        {
          fmessages ? (<Alert variant={'danger'}>{fmessages}</Alert>) : ''
        }
      </Row>
      {/* new product */}
      <Row className='justify-content-end mb-3'>
        <Col sm="3" className={`${i18n.language == "en" ? "text-end" : "text-start"}`}><button className='btn bg-dark text-white' style={{ visibility: cardaddbtnstate }} onClick={handleaddform}>Add New product</button></Col>
      </Row>
      {/* table to display products */}
      <Row style={{overflowX:"scroll"}}>
        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th>Product Id</th>
              <th>Category</th>
              <th>Logo</th>
              <th>Name_En</th>
              <th>Name_Ar</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              products.length >= 1 ? (products.map(item => {
                return (
                  <tr key={item.id} style={{ verticalAlign: "middle" }}>
                    <td>{item.id}</td>
                    <td>{item.category.name_en}</td>
                    <td>
                      <Figure.Image
                        width={100}
                        height={70}
                        alt="100x70"
                        style={{ width: "100px", height: "70px" }}
                        src={item.image_url}
                      />
                    </td>
                    <td>{item.name_en}</td>
                    <td>{item.name_ar}</td>
                    <td>
                      <button className='btn' onClick={() => deletProduct(item.id)}><i className="bi bi-file-x text-danger mx-2"></i></button>
                      <button className='btn text-white' onClick={() => handleeditform(item)} ><i className="bi bi-pen-fill"></i></button>
                    </td>
                  </tr>
                );

              })) :
                (
                  <tr >
                    <td colSpan={6} className="text-center">'no data ...'</td>
                  </tr>
                )
            }

          </tbody>
        </Table>
      </Row>

    </div>
  )
}

export default Products
