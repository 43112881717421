import React from 'react'
import { useTranslation } from 'react-i18next';
import  tiktok  from "../images/tik-tok.png";
const TopBar = () => {
  const [t,i18n]=useTranslation();
  return (
    <div className="container-fluid  p-0 " >
   
      <div className="row gx-0 d-none d-md-flex text-white toppar" style={{backgroundColor:"#1D8BA8"}}>
        <div className={`col-lg-6 px-5 ${i18n.language=='en' ? 'text-start':'text-end'}`}>
         
          <div className="h-100 d-inline-flex align-items-center py-3">
            <small className={`far fa-clock topbar ${i18n.language=='en' ? 'me-2':'ms-2'} `}></small>
            <small style={{fontSize:"16px"}}>{t("Str - Thu : 09.00 AM - 09.00 PM")}</small>
          </div>
        </div>
        <div className={`col-lg-6 px-3 ${i18n.language=='en' ? 'text-end':'text-start'}`}>
          <div className="h-100 d-inline-flex align-items-center py-3 me-4">
            <small className={`fa fa-phone-alt topbar ${i18n.language=='en' ? 'me-2':'ms-2'}`}></small>
            <small>{"0553333937"}</small>
          </div>
          <div className="h-100 d-inline-flex align-items-center">
            <a className="btn btn-sm-square  topbar me-1" href="https://www.facebook.com/BesanDesigns/"
             target={'_blank'} ><i className="fab fa-facebook-f"></i></a>
            <a className="btn btn-sm-square  topbar me-1" href="https://twitter.com/BesanDesigns"
            target={'_blank'}  ><i className="fab fa-twitter"></i></a>
            <a className="btn btn-sm-square  topbar me-1" href="https://www.instagram.com/besan_designs/"
            target={'_blank'}  ><i className="fab fa-instagram"></i></a>
            <a className="btn btn-sm-square  topbar me-1" href="https://www.linkedin.com/in/BesanDesigns"
            target={'_blank'}  ><i className="fab fa-linkedin-in"></i></a>
            <a className="btn btn-sm-square  topbar me-1" href="https://wa.me/966553333937"
            target={'_blank'}  ><i className="fab fa-whatsapp"></i></a>
            <a className="btn btn-sm-square  topbar me-1" href="https://m.me/BesanDesigns"
            target={'_blank'}  ><i className="fab fa-facebook-messenger"></i></a>
            <a className="btn btn-sm-square  topbar me-1" href="https://www.snapchat.com/add/besandesigns"
            target={'_blank'}  ><i className="fab fa-snapchat"></i></a>
            <a className="btn btn-sm-square  topbar me-1" href="https://youtube.com/channel/UCSK8DQ3EYYCNvDTEQ7QTomw"
            target={'_blank'}  ><i className="fab fa-youtube"></i></a>
            <a className="btn btn-sm-square  topbar me-1" href="https://t.me/besandesigns"
            target={'_blank'}  ><i className="fab fa-telegram"></i></a>
            
            <a className="btn btn-sm-square  topbar me-1" href="https://www.tiktok.com/@besandesign"
            target={'_blank'}  > <img
            alt=""
            src={tiktok}
            width="15"
            height="15"
            className="topbar fab d-inline-block "
          /> </a>
            
            
          </div>
        </div>
      </div>
     
    </div>
  )
}

export default TopBar
