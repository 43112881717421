import React, { useState, useRef } from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import Categories from './Categories';
import Products from './Products';
import axios from 'axios';
import { host, config } from '../actions';
const TodoProject = () => {
  const ref1 = useRef();
  const [contentsate, setcontentstate] = useState('none');
  const [formstate, setformstate] = useState('block');
  const [fmessages, setfmessages] = useState('');
  const [userdata, setuserdata] = useState({});

  const checkuser = (e) => {
    e.preventDefault();
    axios.post(host + '/api/user',
    {
    name:userdata.name,
    password:userdata.password
    },
    config).then(res => {
      if (res.data==true) {
        setformstate('none');
        setcontentstate('block')
        setfmessages('')
      } else {
        setfmessages('invalid user')
      }


    }, err => {
      setfmessages(err.response.data.message)
    }

    )
  }
  function hundleuser(e) {
    const newcat = { ...userdata };
    newcat[e.target.id] = e.target.value;
    setuserdata(newcat);
  }
  const signOut = () => {
    setformstate('block');
    setcontentstate('none');
    setuserdata('');
    ref1.current.reset()

  }
  return (
    <div className='container'>
      {/* form to check admin */}
      <Row className='justify-content-center' >
        <Col md="4" style={{ display: formstate }}>
          <Form className='my-5' onSubmit={(e) => checkuser(e)} ref={ref1}>
            <Form.Group className="mb-3" >
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name='name' onChange={(e) => hundleuser(e)} id='name' value={userdata.name} placeholder="Enter UserName" required />
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Label>  password</Form.Label>
              <Form.Control type="password" name='password' id='password' onChange={(e) => hundleuser(e)} value={userdata.email} placeholder="Enter password" required />
            </Form.Group>

            <Button variant="primary " type="submit">
              Login
            </Button>
          </Form>
        </Col>

      </Row>

      {/* flash message */}
      <Row>
        {
          fmessages ? (<Alert variant={'danger'}>{fmessages}</Alert>) : ''
        }
      </Row>


      {/* content of page manager */}
      <div className='container' style={{ display: contentsate }}>
        <ul className="nav nav-pills my-5 justify-content-sm-start justify-content-md-center position-relative" id="pills-tab" role="tablist">
          <li className="nav-item " role="presentation">
            <button className="ptn   active mx-2 " style={{ borderRadius: "10px", fontSize: "18px", color: "black" ,    width: "100px"}} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Products</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="ptn " id="pills-profile-tab" style={{ borderRadius: "10px", fontSize: "18px", color: "black",width: "100px" }} data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Categories</button>
          </li>
          <button className='btn bg-dark text-white position-absolute' onClick={() => signOut()} style={{ top: "0px", right: "0px",height:"35px" }}>Log Out</button>
        </ul>
        <div className="tab-content" id="pills-tabContent">



          {/* start compains */}
          <Categories />
          {/* Website */}
          <Products />
        </div>

      </div>
    </div>
  )
}

export default TodoProject
