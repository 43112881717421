import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translatear from './lang/ar.json'
import translateen from './lang/en.json'
// import { LanguageDetectorModule } from "i18next.browser-languagedetector";
const resources = {
  en: {
    translation: translateen
  },
  ar: {
    translation: translatear
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", 
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react:{
      useSuspense:false
    }
  });

  export default i18n;