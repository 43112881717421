import React ,{useState}from 'react';
import { Container, Nav, Navbar, NavLink } from 'react-bootstrap';
import {BrowserRouter,Link} from 'react-router-dom';
import '../css/style.css';
import TopBar from './TopBar';
import  logoheader  from "../images/besanlogo01.png";
import { useTranslation } from 'react-i18next';

const Header = () => {
  const [t,i18n]=useTranslation();
  const [fix, setfix] = useState(false);
  const [expanded, setExpanded] = useState("lg");

  const checkscroll=()=>{
    if(window.scrollY >= 50){
      setfix(1)
    }else{
      setfix(false)
    }
    
  }
  window.addEventListener('scroll',checkscroll)
  return (
  <div>
  <TopBar />
  
  <Navbar  className={`navanimation${fix ? ' navstiky' : ''} `} style={{backgroundColor:"black",zIndex:"100"}}  variant="dark" expand={expanded}>
  <Container >

    <Navbar.Brand className='' href="/"  style={{color:"#eaa636",fontSize:"24px",fontWeight:"bold",margin:"0px"}}>
      <img
        alt=""
        src={logoheader}
        width="30"
        height="30"
        className="d-inline-block align-top"
      /> {t('Besan Designs')}
      
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav  ms-0"  />
    <Navbar.Collapse id="basic-navbar-nav"  className='justify-content-end'>
      <Nav >
        <Link   className='footerlinks ' to="/homestatic" >{t('home')}</Link>
        <Link   className='footerlinks '  to="/services" >{t('services')}</Link>
        <Link   className='footerlinks '  to="/contact" >{t('contact')}</Link>
        <Link   className='footerlinks '  to="/about" >{t('about')}</Link>
        {i18n.language=="en" && <button className ='langptn footerlinks'  onClick={()=>i18n.changeLanguage('ar')}>العربية</button>}
        {i18n.language=="ar"  && <button className='langptn footerlinks'  onClick={()=>i18n.changeLanguage('en')}>English</button>}
      </Nav>
  </Navbar.Collapse>
  </Container>
</Navbar>
  </div>
    

  )
}

export default Header
