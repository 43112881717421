import React from 'react';
import '../css/style.css';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import tiktok from "../images/tik-tok.png";
import logoheader from "../images/besanlogo01.png";
import { Link } from 'react-router-dom';
const Footer = () => {
  const [t, i18n] = useTranslation();
  return (
    <div style={{ width: "100%", backgroundColor: "#1D8BA8" }}>
      <Container>
        <MDBFooter style={{ backgroundColor: "#1D8BA8" }} className='text-center text-lg-start text-muted'>
          <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
            <div className='d-none d-lg-block'>
              <span style={{ color: "white", fontSize: "18px" }}>{t('Get connected with us on social networks')}</span>
            </div>

            <div>

              <a className="btn btn-sm-square  topbar me-1" href="https://www.facebook.com/BesanDesigns/"
                target={'_blank'} ><i className="fab fa-facebook-f"></i></a>
              <a className="btn btn-sm-square  topbar me-1" href="https://twitter.com/BesanDesigns"
                target={'_blank'}  ><i className="fab fa-twitter"></i></a>
              <a className="btn btn-sm-square  topbar me-1" href="https://www.instagram.com/besan_designs/"
                target={'_blank'}  ><i className="fab fa-instagram"></i></a>
              <a className="btn btn-sm-square  topbar me-1" href="https://www.linkedin.com/in/BesanDesigns"
                target={'_blank'}  ><i className="fab fa-linkedin-in"></i></a>
              <a className="btn btn-sm-square  topbar me-1" href="https://wa.me/966553333937"
                target={'_blank'}  ><i className="fab fa-whatsapp"></i></a>
              <a className="btn btn-sm-square  topbar me-1" href="https://m.me/BesanDesigns"
                target={'_blank'}  ><i className="fab fa-facebook-messenger"></i></a>
              <a className="btn btn-sm-square  topbar me-1" href="https://www.snapchat.com/add/besandesigns"
                target={'_blank'}  ><i className="fab fa-snapchat"></i></a>
              <a className="btn btn-sm-square  topbar me-1" href="https://youtube.com/channel/UCSK8DQ3EYYCNvDTEQ7QTomw"
                target={'_blank'}  ><i className="fab fa-youtube"></i></a>
              <a className="btn btn-sm-square  topbar me-1" href="https://t.me/besandesigns"
                target={'_blank'}  ><i className="fab fa-telegram"></i></a>

              <a className="btn btn-sm-square  topbar me-1" href="https://www.tiktok.com/@besandesign"
                target={'_blank'}  > <img
                  alt=""
                  src={tiktok}
                  width="15"
                  height="15"
                  className="topbar fab d-inline-block "
                /> </a>

            </div>
          </section>



          <section className='' style={{ color: '#eaa636', fontSize: "18px" }}>
            <MDBContainer className={`text-center  mt-5 ${i18n.language == "en" ? "text-md-start" : "text-md-end"}`}>
              <MDBRow className='mt-3'>
                <MDBCol md="4" lg="4" xl="3" className='mx-auto mb-4 align-items-center'>


                  <img
                    alt=""
                    src={logoheader}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                  /><h3 className='mx-2' style={{ display: "inline-block" }}>{t('Besan Designs')}</h3>

                  <p>
                    {t('besanwork')}
                  </p>
                </MDBCol>

                <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4 '>
                  <h6 className='text-uppercase fw-bold mb-4'>{t('Links')}</h6>
                  <p>
                    <Link to="/homestatic" className='text-reset'>
                      {t('home')}
                    </Link>
                  </p>
                  <p>
                    <Link className='text-reset ' to="/services">
                      {t('services')}
                    </Link>
                  </p>
                  <p>
                    <Link to="/contact" className='text-reset'>
                      {t('contact')}
                    </Link>
                  </p>
                  <p>
                    <Link to="/about" className='text-reset'>
                      {t('about')}
                    </Link>
                  </p>
                  <p>
                    <Link to="/todoproject" className='text-reset'>
                      {t('manager')}
                    </Link>
                  </p>
                </MDBCol>
                <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
                  <h6 className='text-uppercase fw-bold mb-4'>{t('Contact')}</h6>
                  <p>
                    <MDBIcon icon="home" className={`${i18n.language == "en" ? "me-3" : "ms-3"}`} />
                    {t('Makkah Al-Mukarramah Al-Shari’a Scheme 10 Public Security Street next to the 10th Al-Badr Medical Complex')}
                  </p>
                  <p>
                    <MDBIcon icon="envelope" className={`${i18n.language == "en" ? "me-3" : "ms-3"}`} />
                    besan.designs@gmail.com
                  </p>
                  <p>
                    <MDBIcon icon="phone" className={`${i18n.language == "en" ? "me-3" : "ms-3"}`} /> 0553333937
                  </p>

                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>

          <div className='text-center p-4 text-white' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
            © 2022 Copyright:
            <a className='text-reset fw-bold' href='https://wzgate.com/'>
              wzgate.com
            </a>
          </div>
        </MDBFooter>
      </Container>
    </div>





  )
}

export default Footer
