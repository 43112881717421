import React from 'react'
import { Card, Col, Row } from "react-bootstrap";
import { Fade, Zoom } from 'react-awesome-reveal';
import '../css/style.css';
// import  product  from "../images/product-1.jpg";
import { useTranslation } from 'react-i18next';


const ListItems = ({itemlists}) => {
  const [t,i18n]=useTranslation(); 
  return (
   
      <Row className='mb-3 justify-content-center ms-1' >
   
       {
        itemlists.length >0 ? (itemlists.map((item) =>{
               return( 
                <Col key={item.id} md="4" className='p-1 my-2' >
                 <Zoom>
                   <Card className=''>
                         <img className='img-item'  src={item.image_url} />
                   </Card>
                 </Zoom>
                </Col>
                )

        })):<h3 className='text-white text-center'>{t('No Data')}</h3>
       }
       
      
      </Row>
      
   
  )
}

export default ListItems
