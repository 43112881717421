import React, { useState, useEffect } from 'react';
import Category from './Category';
// import data  from "./Bdata";
import axios from 'axios';
import { Container, Row } from 'react-bootstrap';
import ListItems from './ListItems';
import { host } from './actions';

const ReturnMenu = () => {
 
  const [allproducts, setallproducts] = useState({});
  const [itemsData, setItemsData] = useState({});
  const [categories, setCategories] = useState([]);


  {/* const data = [
    { id: 2, category: t('External Panels'), title: 'designx', urlimg: 'images/ep1.jpeg' },
    { id: 23, category: t('External Panels'), title: 'designx', urlimg: 'images/ep2.jpeg' },
    { id: 24, category: t('External Panels'), title: 'designx', urlimg: 'images/ep3.jpeg' },
    { id: 25, category: t('External Panels'), title: 'designx', urlimg: 'images/ep4.jpeg' },
    { id: 26, category: t('External Panels'), title: 'designx', urlimg: 'images/ep5.jpeg' },
    { id: 27, category: t('External Panels'), title: 'designx', urlimg: 'images/ep6.jpeg' },
    { id: 28, category: t('External Panels'), title: 'designx', urlimg: 'images/ep7.jpeg' },
    { id: 29, category: t('External Panels'), title: 'designx', urlimg: 'images/ep8.jpeg' },
    { id: 30, category: t('External Panels'), title: 'designx', urlimg: 'images/ep9.jpeg' },
    { id: 31, category: t('External Panels'), title: 'designx', urlimg: 'images/ep10.jpeg' },
    { id: 32, category: t('External Panels'), title: 'designx', urlimg: 'images/ep11.jpeg' },
    { id: 33, category: t('External Panels'), title: 'designx', urlimg: 'images/ep12.jpeg' },
    { id: 34, category: t('External Panels'), title: 'designx', urlimg: 'images/ep13.jpeg' },
    { id: 36, category: t('External Panels'), title: 'designx', urlimg: 'images/ep15.jpeg' },
    { id: 37, category: t('External Panels'), title: 'designx', urlimg: 'images/ep16.jpeg' },
    { id: 38, category: t('External Panels'), title: 'designx', urlimg: 'images/ep17.jpeg' },
    { id: 39, category: t('External Panels'), title: 'designx', urlimg: 'images/ep18.jpeg' },
    { id: 500, category: t('External Panels'), title: 'designx', urlimg: 'images/ep19.jpeg' },

    { id: 3, category: t('Decoration'), title: 'designxx', urlimg: 'images/d1.jpeg' },
    { id: 40, category: t('Decoration'), title: 'designxx', urlimg: 'images/d4.jpg' },
    { id: 41, category: t('Decoration'), title: 'designxx', urlimg: 'images/d5.jpg' },
    { id: 42, category: t('Decoration'), title: 'designxx', urlimg: 'images/d3.jpeg' },
    { id: 43, category: t('Decoration'), title: 'designxx', urlimg: 'images/d6.jpg' },
    { id: 1000, category: t('Decoration'), title: 'designxx', urlimg: 'images/d7.jpg' },
    { id: 1001, category: t('Decoration'), title: 'designxx', urlimg: 'images/d8.jpeg' },
    { id: 1002, category: t('Decoration'), title: 'designxx', urlimg: 'images/d9.jpeg' },
    { id: 1003, category: t('Decoration'), title: 'designxx', urlimg: 'images/d10.jpeg' },
    { id: 1004, category: t('Decoration'), title: 'designxx', urlimg: 'images/d11.jpeg' },
    { id: 1005, category: t('Decoration'), title: 'designxx', urlimg: 'images/d12.jpeg' },
    { id: 1006, category: t('Decoration'), title: 'designxx', urlimg: 'images/d13.jpeg' },
    { id: 1007, category: t('Decoration'), title: 'designxx', urlimg: 'images/d14.jpeg' },
    { id: 1008, category: t('Decoration'), title: 'designxx', urlimg: 'images/d15.jpeg' },
    { id: 1009, category: t('Decoration'), title: 'designxx', urlimg: 'images/d16.jpeg' },
    { id: 1010, category: t('Decoration'), title: 'designxx', urlimg: 'images/d17.jpeg' },
    { id: 1011, category: t('Decoration'), title: 'designxx', urlimg: 'images/d18.jpeg' },
    { id: 1012, category: t('Decoration'), title: 'designxx', urlimg: 'images/d19.jpeg' },
    { id: 1013, category: t('Decoration'), title: 'designxx', urlimg: 'images/d20.jpeg' },
    { id: 1014, category: t('Decoration'), title: 'designxx', urlimg: 'images/d21.jpeg' },

    { id: 4, category: t('Shields And Gifts'), title: 'designxxx', urlimg: 'images/sg1.jpg' },
    { id: 50, category: t('Shields And Gifts'), title: 'designxxx', urlimg: 'images/sg2.jpg' },
    { id: 51, category: t('Shields And Gifts'), title: 'designxxx', urlimg: 'images/sg3.jpg' },
    { id: 52, category: t('Shields And Gifts'), title: 'designxxx', urlimg: 'images/sg4.jpg' },
    { id: 53, category: t('Shields And Gifts'), title: 'designxxx', urlimg: 'images/sg5.webp' },
    { id: 54, category: t('Shields And Gifts'), title: 'designxxx', urlimg: 'images/sg6.webp' },
    { id: 55, category: t('Shields And Gifts'), title: 'designxxx', urlimg: 'images/sg7.jpeg' },
    { id: 56, category: t('Shields And Gifts'), title: 'designxxx', urlimg: 'images/sg8.jpeg' },


    { id: 5, category: t('E-Marketing'), title: 'printingx', urlimg: 'images/55.png' },
    { id: 6, category: t('Online Services'), title: 'printingxx', urlimg: 'images/55.png' },
    { id: 7, category: t('Paper Publications'), title: 'printingxxx', urlimg: 'images/pp1.jpeg' },
    { id: 8, category: t('Paper Publications'), title: 'printingxxxx', urlimg: 'images/pp2.jpeg' },
    { id: 9, category: t('Paper Publications'), title: 'printingxxxxx', urlimg: 'images/pp3.jpeg' },
    { id: 66, category: t('Paper Publications'), title: 'printingxxxxx', urlimg: 'images/pp4.jpeg' },
    { id: 67, category: t('Paper Publications'), title: 'printingxxxxx', urlimg: 'images/pp5.jpg' },
    { id: 69, category: t('Paper Publications'), title: 'printingxxxxx', urlimg: 'images/pp6.png' },
    { id: 70, category: t('Paper Publications'), title: 'printingxxxxx', urlimg: 'images/pp7.jpeg' },
    { id: 71, category: t('Paper Publications'), title: 'printingxxxxx', urlimg: 'images/pp8.jpeg' },
    { id: 72, category: t('Paper Publications'), title: 'printingxxxxx', urlimg: 'images/pp9.jpeg' },
    { id: 73, category: t('Paper Publications'), title: 'printingxxxxx', urlimg: 'images/pp10.jpeg' },
    { id: 74, category: t('Paper Publications'), title: 'printingxxxxx', urlimg: 'images/pp11.jpeg' },
    { id: 75, category: t('Paper Publications'), title: 'printingxxxxx', urlimg: 'images/pp12.jpeg' },




  ] */}


  useEffect(() => {
    getAllProducts()
    getAllCats()

  }, []);

  function getAllProducts() {
    axios.get(host+'/api/products').then(res => {
      setallproducts(res.data);
      setItemsData(res.data)
    });
  }
  function getAllCats() {
    axios.get(host+'/api/categories').then(res => {
      setCategories(res.data);
    });
  }



  // filter Category
  const OnFilter = (cat) => {
    if (cat == 'all') {
      setItemsData(allproducts);
    } else {
      const newsate = allproducts.filter((product) => product.cat_id == parseInt(cat));
      setItemsData(newsate);
    }

  };

  return (
    <div>
      <Container>
        <Row>
          <Category OnFilter={OnFilter} cats={categories} />
        </Row>

        <Row>
          <ListItems itemlists={itemsData}></ListItems>
        </Row>
      </Container>


    </div>
  )
}

export default ReturnMenu;

