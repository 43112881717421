import React from 'react'
import { Figure } from 'react-bootstrap'
import  logo  from "../images/besanlogo01.png";
const LogoFiger = () => {
  return (
    
        <Figure className='logofigur'>
          <Figure.Image
            width={121}
            height={140}
            alt="121x141"
            src={logo}
          />
          
        </Figure>
  )
}

export default LogoFiger
