import React from 'react'
import { Col, Row } from "react-bootstrap";
import { Slide } from 'react-awesome-reveal';
import '../css/style.css';
import { useTranslation } from 'react-i18next';
const Category = ({ cats, OnFilter }) => {

  const [t, i18n] = useTranslation();
  const handleFilter = (cat) => {
    console.log(cat);
    OnFilter(cat);


  }



  return (

    <div className='p-5'>
      <Row className='mt-4 mb-2 '>
        <Col className='d-flex justify-content-center'>
          <div style={{ fontSize: "16px", fontWeight: "bold", color: "white" }}>
            <h1>{t('Our Services')}</h1>
          </div>
        </Col>

      </Row>
      <Row className='d-flex justify-content-center '>
        <Col className='d-flex justify-content-center'>
          <div className='underline'></div>
        </Col>
      </Row>

      <Row className='mt-3 mb-2'>
        <Slide top duration={1000}>
          <Col className='d-flex justify-content-center flex-wrap'>
            <div  className="mb-1">
              <button className='ptn mx-2' style={{ width: "200px" }} onClick={() => { handleFilter('all') }}>{t('All')}</button>
            </div>
            {cats.length > 0 ? (cats.map((cat) => {
              return (<div key={Math.random()} className="mb-1">
                <button className='ptn mx-2' style={{ width: "200px" }} onClick={() => { handleFilter(cat.id) }}>{i18n.language=="en" ? cat.name_en:cat.name_ar}</button>
              </div>

              )
            })) : ''

            }

          </Col>
        </Slide>
      </Row>
    </div>
  )
}

export default Category
