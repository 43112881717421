
import '../App.css';
import Footer from './Footer';
import Header from './Header';
import Contact from './Contact';
import About from './About';
import Error from './Error';
import {BrowserRouter,Route,Routes} from 'react-router-dom';
import {useState} from 'react';
import Home from './Home';
import Services from './Services';
import { useTranslation } from 'react-i18next';
import Homestatic from './Homestatic';
import TodoProject from './backend/TodoProject';


function App() {
 const [state, setstate] = useState(["hide","visible"]);
 const [t,i18n]=useTranslation();
 const handlevisibility=(e)=>{
  if(state[0]=="hide"){
    setstate(["show","hidden"])
    
  }else{
    setstate(["hide","visible"])
  
  }
 }
  return (
   
        <div  className="position-relative appstyle" dir={i18n.language=="en" ? "ltr":"rtl"}>
         <BrowserRouter>
            <Header />
            <Routes>
              <Route  path='/' element={<Home />} />
              <Route  path='/homestatic' element={<Homestatic />} />
              <Route  path='/services'    element={<Services />} />
              <Route  path='/about'    element={<About />} />
              <Route  path='/contact'  element={<Contact />}/>
              <Route  path='/todoProject'  element={<TodoProject />}/>
              <Route  path='*' element={<Error/>}/>
            </Routes>
            <Footer />
         </BrowserRouter>
         </div>
   
  );
}

export default App;
